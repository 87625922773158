@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .hover-underline::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 1.5px;
        background-color: currentColor;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.5s ease;
        }
      
    .hover-underline:hover::after {
        transform: scaleX(1);
        transform-origin: right;
        }
    /* ... */
  }